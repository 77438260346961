import * as React from "react";
import { CloudIcon, ServerIcon, WifiIcon } from "@heroicons/react/solid";

import PageTitle from "../../components/elements/PageTitle";
import Header from "../../components/sections/Header";
import ModelPageSection from "../../components/sections/ModelPageSection";
import AttachmentsSection from "../../components/sections/AttachmentsSection";
import AttachmentItem from "../../components/elements/AttachmentItem";
import HighlightsSection from "../../components/sections/HighlightsSection";
import HighlightItem from "../../components/elements/HighlightItem";
import DistributorsSection from "../../components/sections/DistributorsSection";
import DistributorItem from "../../components/elements/DistributorItem";
import Footer from "../../components/sections/Footer";

import certificateFile from "../../images/C2Y-CU-001-01-39 ÄB16 Certifikat_typeС.jpg";
import techSpecFile from "../../images/tech_spec_posplus_II.png";
import LinkSection from "../../components/sections/LinkSection";

const ModelCEnPage = () => (
  <div className="bg-white">
    <PageTitle lang={"en"} pageTitle={"Model C"} description={""} />
    <Header lang={"en"} />
    <ModelPageSection
      titleSection={"Model C"}
      descriptionSection={""}
      modelTitle={"Model C - For server and multiple cash registers"}
      modelDescription={`PosPlus Model C is intended to be connected to a central server, locally or via the Internet and thus function as a control unit for a number of cash registers. PosPlus provides solutions for both cloud server and local server.`}
      modelImgName={"posplus_blue.jpg"}
      certificatesText={[`For a complete list of certificates click`, `here`]}
      // faqs={[
      //   {
      //     question: "What format are these icons?",
      //     answer:
      //       "The icons are in SVG (Scalable Vector Graphic) format. They can be imported into your design tool of choice and used directly in code.",
      //   },
      //   {
      //     question: "Can I use the icons at different sizes?",
      //     answer:
      //       "Yes. The icons are drawn on a 24 x 24 pixel grid, but the icons can be scaled to different sizes as needed. We don't recommend going smaller than 20 x 20 or larger than 64 x 64 to retain legibility and visual balance.",
      //   },
      // ]}
      licenseSectionTitle="License"
      licenseTitles={["Certificate C UNIT"]}
      licenseContent={[
        `Posplus is a certified product whose label shows which
                          certificate applies to the product. Here is an example
                          of a certificate:`,
      ]}
      certificatesUrl={"https://www.cert2you.se/lista-certifikat-2020"}
      // just add name of photo like this ["certifikat-posplus-scaled.jpg", "certifikat-posplus-scaled.jpg"]
      certificateImgNames={["certifikat-posplus-scaled.jpg", "C2Y-CU-001-01-39 ÄB16 Certifikat_typeС.jpg"]}
      // It's not display because param licenseTitles have no second title
      techSpecContent={[
        `Posplus is a certified product whose label shows which
                          certificate applies to the product. Here is an example
                          of a certificate:`,
      ]}
      // just add name of photo like this ["certifikat-posplus-scaled.jpg", "certifikat-posplus-scaled.jpg"]
      techSpecImgNames={["tech_spec_posplus_II.png"]}
    >
      <AttachmentsSection title={"Attachments"}>
        <AttachmentItem
          fileName={"Certificate"}
          file={certificateFile}
          downloadText="Download"
        />
        <AttachmentItem
          fileName={"Technical specification"}
          file={techSpecFile}
          downloadText="Download"
        />
      </AttachmentsSection>

      <LinkSection
        title="Communication protocol"
        /*description="description"*/
        clickText={[
          "To find REST-API communication protocol description, click ",
          "here",
        ]}
        linkPath="/en/api-doc"
      />

      <LinkSection
        title="Error Codes"
        /*description="description"*/
        clickText={[
          "To find full list of error codes and their definitions, click ",
          "here",
        ]}
        linkPath="/en/error-codes"
      />

      <HighlightsSection title={"Highlights"}>
        <HighlightItem
          name="Cloud server"
          description={`You can also connect your equipment to the PosPlus control unit through our Cloud Server service.`}
          IconImg={CloudIcon}
        />
        <HighlightItem
          name="Local server"
          description={`You can also use PosPlus (Model C) with your own local server.`}
          IconImg={ServerIcon}
        />
        <HighlightItem
          name="Bluetooth-connection"
          description={`PosPlus can be connected to a mobile cash register via Bluetooth.`}
          IconImg={WifiIcon}
        />
      </HighlightsSection>

      <DistributorsSection title={"Distributors"}>
        <DistributorItem
          title={"Postronic AB"}
          email={"info@postronic.se"}
          phone={"+46 (0) 7 0 594 29 54"}
        />
      </DistributorsSection>
    </ModelPageSection>
    <Footer lang={"en"} />
  </div>
);

export default ModelCEnPage;
